/* eslint-disable import/exports-last */
// eslint-disable-next-line import/no-named-as-default
import Alpha2Codes from './Alpha2Codes'
import { cappedString255, requiredCappedString255 } from '../other'
import { keysOf } from '@betterplace/utils'
import { z } from 'zod'

// Country codes that use pure numeric values, with lengths of 3-10 characters
export const GenericPureNumericZipCountryCodes = {
  RU: 'RU',
  TR: 'TR',
  FR: 'FR',
  IT: 'IT',
  ES: 'ES',
  RO: 'RO',
  BE: 'BE',
  GR: 'GR',
  CZ: 'CZ',
  PT: 'PT',
  HU: 'HU',
  CH: 'CH',
  BG: 'BG',
  RS: 'RS',
  DK: 'DK',
  FI: 'FI',
  SK: 'SK',
  NO: 'NO',
} as const

// Country codes that can be served by using the "tel" input for zip codes
export const NumericZipCountryCodes = {
  ...GenericPureNumericZipCountryCodes,
  DE: 'DE',
  PL: 'PL',
} as const

export const AllRemainingCountryCodes = {
  ...(Alpha2Codes as Omit<typeof Alpha2Codes, keyof typeof NumericZipCountryCodes>),
} as const

for (const cc of keysOf(NumericZipCountryCodes)) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  delete (AllRemainingCountryCodes as any)[cc]
}

const numericZip = z.coerce
  .string()
  .min(1)
  .regex(/[0-9]+/)
export const numericZipString = numericZip.min(3).max(10)
export const deZipString = numericZip.min(5).max(5)
export const plZipString = z
  .string()
  .min(1)
  .regex(/^\d{2}-\d{3}$/)

type ZipSchemaType<T extends z.ZodRawShape> = z.ZodObject<
  T,
  'strip',
  z.ZodTypeAny,
  z.output<z.ZodObject<T>>,
  z.input<z.ZodObject<T>>
>

type ObjectShape<
  TCountryCodeKey extends string,
  TZipCodeKey extends string,
  TCountryCode extends z.ZodTypeAny,
  TZipCode extends z.ZodTypeAny,
  TOptional extends boolean | undefined,
> = TOptional extends true
  ? {
      [Key in TCountryCodeKey]: TCountryCode
    } & { [Key in TZipCodeKey]: z.ZodOptional<TZipCode> }
  : {
      [Key in TCountryCodeKey]: TCountryCode
    } & { [Key in TZipCodeKey]: TZipCode }

function getZipCodeSchema<TCountryCodeKey extends string, TZipCodeKey extends string>({
  countryAlpha2Key,
  zipCodeKey,
  optional,
}: {
  countryAlpha2Key: TCountryCodeKey
  zipCodeKey: TZipCodeKey
  optional?: boolean
}) {
  type ObjectShapeDE = ObjectShape<
    TCountryCodeKey,
    TZipCodeKey,
    z.ZodLiteral<'DE'>,
    typeof deZipString,
    typeof optional
  >
  const deZipStringMaybeOptional = optional ? deZipString.optional().or(z.literal('')) : deZipString
  const deSchema = z.object({
    [countryAlpha2Key]: z.literal('DE'),
    [zipCodeKey]: deZipStringMaybeOptional,
  }) as ZipSchemaType<ObjectShapeDE>

  type ObjectShapePL = ObjectShape<
    TCountryCodeKey,
    TZipCodeKey,
    z.ZodLiteral<'PL'>,
    typeof plZipString,
    typeof optional
  >

  const plZipStringMaybeOptional = optional ? plZipString.optional().or(z.literal('')) : plZipString

  const plSchema = z.object({
    [countryAlpha2Key]: z.literal('PL'),
    [zipCodeKey]: plZipStringMaybeOptional,
  }) as ZipSchemaType<ObjectShapePL>

  type ObjectShapeGenericNumeric = ObjectShape<
    TCountryCodeKey,
    TZipCodeKey,
    z.ZodNativeEnum<typeof GenericPureNumericZipCountryCodes>,
    typeof numericZipString,
    typeof optional
  >
  const numericZipStringMaybeOptional = optional ? numericZipString.optional().or(z.literal('')) : numericZipString
  const genericNumeric = z.object({
    [countryAlpha2Key]: z.nativeEnum(GenericPureNumericZipCountryCodes),
    [zipCodeKey]: numericZipStringMaybeOptional,
  }) as ZipSchemaType<ObjectShapeGenericNumeric>

  type ObjectShapeGeneric = ObjectShape<
    TCountryCodeKey,
    TZipCodeKey,
    z.ZodNativeEnum<typeof AllRemainingCountryCodes>,
    typeof requiredCappedString255,
    typeof optional
  >
  const genericZipStringMaybeOptional = optional ? cappedString255 : requiredCappedString255
  const generic = z.object({
    [countryAlpha2Key]: z.nativeEnum(AllRemainingCountryCodes),
    [zipCodeKey]: genericZipStringMaybeOptional,
  }) as ZipSchemaType<ObjectShapeGeneric>

  const res = z.union([deSchema, plSchema, genericNumeric, generic])
  // In order to simplify type we cast it to a generic one, it is still a union never the less
  return res as unknown as ZipSchemaType<
    ObjectShape<
      TCountryCodeKey,
      TZipCodeKey,
      z.ZodNativeEnum<typeof Alpha2Codes>,
      typeof genericZipStringMaybeOptional,
      typeof optional
    >
  >
}

export default getZipCodeSchema
